.navigation_search.show .header_content_item__block {
  top: 55px;
  visibility: visible;
  opacity: 1; }

.header_content_item {
  display: block;
  width: calc(100% - 70px); }
  .header_content_item__block {
    transition: all .2s;
    display: block;
    position: absolute;
    top: 155px;
    opacity: 0;
    visibility: hidden;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.16);
    border-radius: 2px;
    max-height: 400px;
    overflow-y: auto; }
  .header_content_item_title {
    transition: all .2s;
    display: block; }
  .header_content_item_type {
    display: block;
    margin: 5px 0;
    color: #000; }
  .header_content_item_price {
    display: block; }
  .header_content_item_img {
    display: block;
    position: relative;
    width: 60px;
    height: 60px;
    margin-right: 10px; }
    .header_content_item_img img {
      width: 100%;
      height: 100%;
      object-fit: contain; }
  .header_content_item_error {
    width: 100%;
    text-align: center; }
  .header_content_item_profile {
    transition: all .2s;
    background: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 10px; }
    .header_content_item_profile:hover .header_content_item_title {
      color: #F28820; }
