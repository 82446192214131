.thanks_section {
  position: relative;
  width: 100%;
  height: calc(70vh - 265px); }

.thanks_bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; }

.thanks__block {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 70px;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.thanks_title {
  display: flex;
  align-items: center; }
  .thanks_title svg {
    width: 50px;
    height: 50px;
    margin: 0 20px; }

.thanks_subtitle {
  margin-top: 15px;
  margin-bottom: 30px; }

.thanks_btn {
  text-transform: uppercase; }

.error__block {
  position: absolute;
  width: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.stock_items__block {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin: 15px -15px; }

.stock_items_item {
  margin: 15px;
  width: calc(33.3% - 30px);
  border-radius: 6px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  background: #fff; }

.stock_items_img {
  width: 100%; }
  .stock_items_img img {
    width: 100%;
    height: auto;
    object-fit: cover; }

.stock_items_text {
  margin: 15px 0;
  width: 100%;
  text-align: center; }

.stock_items_content {
  margin-bottom: 25px;
  width: 100%; }

.stock_items_subtext {
  width: 100%;
  text-align: right; }

.password_reset {
  margin-bottom: 30px;
  width: 100%;
  max-width: 800px; }

@media (max-width: 992px) {
  .stock_items_item {
    width: 100%;
    margin: 15px 0; }
  .stock_items__block {
    margin: 15px 0; }
  .thanks__block {
    width: 100%;
    max-width: 300px;
    background: rgba(255, 255, 255, 0.392);
    border-radius: 8px;
    top: 50%;
    padding: 20px 10px;
    bottom: unset;
    transform: translate(-50%, -50%); } }
