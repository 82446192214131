.basket_modal_wrapper {
  display: none;
  flex-direction: column;
  transition: all 0.2s;
  padding: 30px;
  max-width: 500px;
  width: 100%;
  max-height: 75vh;
  background: #fff;
  position: fixed;
  top: 87px;
  left: 100%;
  border-radius: 8px;
  transform: translateX(-115%);
  -webkit-box-shadow: 14px 10px 13px -6px #bdbdbd;
  -moz-box-shadow: 14px 10px 13px -6px #bdbdbd;
  box-shadow: 14px 10px 13px -6px #bdbdbd; }
  @media (max-width: 992px) {
    .basket_modal_wrapper {
      transform: translateX(-100%);
      top: 110px;
      max-height: calc(100% - 110px); } }
  @media (max-width: 650px) {
    .basket_modal_wrapper {
      max-height: none;
      max-width: none;
      width: 100vw;
      height: 100%;
      top: 0px;
      border-radius: 0; } }
  @media (max-width: 500px) {
    .basket_modal_wrapper {
      padding: 15px; } }
  .basket_modal_wrapper.active {
    display: flex;
    z-index: 9999; }
  .basket_modal_wrapper .basket_modal_close {
    transition: all 0.2s;
    color: #0000007a;
    cursor: pointer;
    display: flex;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 20px;
    right: 20px; }
    .basket_modal_wrapper .basket_modal_close:hover {
      color: #73cac2; }

.basket_currency {
  font-size: 11px; }

.basket_mob .basket_item {
  display: flex;
  width: 100%;
  padding: 15px;
  border-bottom: 1px solid #4a4652; }
  .basket_mob .basket_item_price_old {
    text-decoration: line-through; }
  .basket_mob .basket_item_price__block {
    display: flex;
    width: 100px;
    flex-direction: column;
    justify-content: flex-end; }
    @media (max-width: 440px) {
      .basket_mob .basket_item_price__block {
        width: auto; } }
  .basket_mob .basket_item_price_all {
    width: 100px;
    text-align: end; }
    @media (max-width: 440px) {
      .basket_mob .basket_item_price_all {
        width: auto; } }
  .basket_mob .basket_item_top {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between; }
  .basket_mob .basket_item_middle {
    display: flex;
    align-items: center;
    margin-right: 0;
    padding-top: 5px;
    width: 100%;
    justify-content: space-between; }
  .basket_mob .basket_item__right {
    width: 100%; }
  .basket_mob .basket_item_bottom {
    display: flex;
    align-items: center;
    padding-left: 127px;
    width: 100%;
    padding-top: 20px;
    justify-content: space-between; }
  .basket_mob .basket_item_mobile_info {
    display: inline-block; }
  .basket_mob .basket_item_delete {
    display: flex;
    align-items: center; }
    .basket_mob .basket_item_delete svg {
      width: 13px;
      height: 14px;
      margin-right: 12px; }
      .basket_mob .basket_item_delete svg path {
        transition: all 0.2s; }
    .basket_mob .basket_item_delete div {
      transition: all 0.2s; }
    .basket_mob .basket_item_delete:hover path {
      fill: #eb5757; }
    .basket_mob .basket_item_delete:hover div {
      color: #eb5757; }
  .basket_mob .basket_item_img {
    width: 92px;
    min-height: 92px;
    border: 1px solid #f3f3f3;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 35px; }
    .basket_mob .basket_item_img img {
      width: 60px;
      height: 60px;
      object-fit: contain; }
  .basket_mob .basket_item_title {
    width: 100%;
    margin-right: 10px; }

.basket_mob .basket_product_container {
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1; }
  @media (max-width: 650px) {
    .basket_mob .basket_product_container {
      height: 100%; } }

.basket_mob .basket_modal_bottom {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  margin-top: 20px; }
  .basket_mob .basket_modal_bottom_container .modal_close {
    height: auto; }
  .basket_mob .basket_modal_bottom .total_sum__title {
    color: #373843;
    opacity: 0.6; }
  .basket_mob .basket_modal_bottom .total_sum__value-number {
    font-size: 30px; }
  .basket_mob .basket_modal_bottom .total_sum__value-currency {
    font-size: 18px; }
  .basket_mob .basket_modal_bottom .basket_modal__btn {
    white-space: nowrap; }
    .basket_mob .basket_modal_bottom .basket_modal__btn .modal_close {
      padding: 15px; }
    .basket_mob .basket_modal_bottom .basket_modal__btn .to-basket {
      color: #586b7a;
      margin-left: 10px;
      border-bottom: 1px solid; }
  .basket_mob .basket_modal_bottom_block {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .basket_mob .basket_modal_bottom_block .return {
      padding: 0 10px; }
      .basket_mob .basket_modal_bottom_block .return span {
        padding: 2px 5px;
        border: 1px solid;
        border-radius: 8px; }
      .basket_mob .basket_modal_bottom_block .return .modal_close {
        color: #586b7a;
        position: static;
        width: auto; }
    .basket_mob .basket_modal_bottom_block .ordering {
      padding: 0 10px;
      text-transform: uppercase; }
      .basket_mob .basket_modal_bottom_block .ordering a {
        white-space: nowrap;
        padding: 15px; }

.basket_mob .count__block {
  display: flex;
  align-items: center; }

.basket_mob .count_minus_basket, .basket_mob .count_plus_basket {
  cursor: pointer;
  transition: all 0.2s;
  position: relative;
  background: #586b7a;
  border-radius: 8px;
  width: 36px;
  height: 36px; }
  .basket_mob .count_minus_basket:after, .basket_mob .count_plus_basket:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 1px;
    background: #fff; }
  .basket_mob .count_minus_basket:hover, .basket_mob .count_plus_basket:hover {
    background: #445664; }

.basket_mob .count_plus_basket:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1px;
  height: 8px;
  background: #fff; }

.basket_mob .count_input_basket {
  transition: all 0.2s;
  background: transparent;
  outline: none;
  border: none;
  margin: 0px 10px;
  width: 36px;
  height: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 4px; }

@media (max-width: 600px) {
  .basket_mob .basket_modal_bottom {
    margin-bottom: 10px;
    margin-top: 0px; }
  .basket_mob .ordering {
    width: 70%; }
    .basket_mob .ordering a {
      font-size: 14px; } }

@media (max-width: 440px) {
  .basket_mob .basket_item_middle {
    padding-left: 0; }
  .basket_mob .basket_item_bottom {
    padding-left: 0; }
  .basket_mob .total_sum__title {
    margin: 10px 0; } }
