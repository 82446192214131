.bread_crumbs_section {
  display: none;
  position: relative;
  padding: 20px 0px; }

.bread_crumbs__block {
  display: flex;
  align-items: center;
  flex-wrap: wrap; }

.bread_crumbs_slash {
  width: 10px;
  height: 10px;
  background: transparent;
  margin: 0px 7px;
  margin-right: 12px;
  border-right: 1px solid #5D6B7C;
  border-top: 1px solid #5D6B7C;
  transform: rotate(45deg); }

.bread_crumbs_link {
  transition: all .2s;
  opacity: 0.7; }
  .bread_crumbs_link.active {
    opacity: 1; }
  .bread_crumbs_link:hover {
    color: #73CAC2;
    opacity: 1; }

@media (max-width: 992px) {
  .bread_crumbs_section {
    padding: 10px 0px; } }
