.delivery_hint__block {
  position: fixed;
  z-index: 999;
  transition: all 0.2s;
  margin-top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000000;
  height: 30px;
  top: 0; }
  .delivery_hint__block.hidden {
    margin-top: -30px; }

.delivery_hint_text {
  display: block;
  padding-left: 10px; }

.delivery_hint_close {
  position: absolute;
  right: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12px;
  height: 12px;
  cursor: pointer; }
  .delivery_hint_close svg {
    width: 100%;
    height: 100%; }
  .delivery_hint_close path {
    transition: all 0.2s;
    stroke: #bdbdbd; }
  .delivery_hint_close:hover path {
    stroke: #73cac2; }

.middle_navigation_shop {
  display: none; }

.middle_navigation_menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 85%; }

.middle_navigation_scroll {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%; }
  .middle_navigation_scroll .top_navigation_link {
    display: none; }

.hidden_menu__block {
  display: none; }

.top_navigation_items {
  display: flex;
  align-items: center; }

.lang__block {
  margin-left: 20px; }
  .lang__block_mobile {
    display: none; }

.lang_link {
  transition: all 0.2s;
  margin-left: 5px;
  border-bottom: 1px solid transparent; }
  .lang_link.active {
    color: #73cac2; }
  .lang_link:hover {
    border-bottom: 1px solid #73cac2; }

.btn_sociate {
  position: relative; }
  .btn_sociate a {
    color: #000000; }
  .btn_sociate:after {
    content: "";
    z-index: -1;
    position: absolute;
    top: 50%;
    background: #fafafa;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    transform: translateY(-50%); }
  .btn_sociate_1:after {
    left: -2px; }
  .btn_sociate_2 {
    margin-right: 20px; }
    .btn_sociate_2:after {
      left: -3px; }

.row_additional {
  font-size: 14px;
  display: flex;
  margin-right: 20px;
  color: #bdbdbd; }

.row {
  display: flex;
  align-items: center;
  margin-top: 15px; }

@keyframes header_animation_fixed {
  0% {
    top: -220px; }
  100% {
    top: 0px; } }

@keyframes header_animation_scroll {
  0% {
    top: 0; }
  100% {
    top: -220px; } }

.header_navigation {
  transition: all 0.2s;
  z-index: 998;
  width: 100%;
  background: #fff;
  top: 0; }
  .header_navigation.header_navigation_delivery {
    padding-top: 30px; }
  .header_navigation.animation {
    animation: header_animation_scroll 0.4s forwards; }
  .header_navigation.fixed {
    animation: header_animation_fixed 0.4s forwards;
    -webkit-box-shadow: 0px 5px 17px 0px #a1e6df;
    -moz-box-shadow: 0px 5px 17px 0px #a1e6df;
    box-shadow: 0px 5px 17px 0px #a1e6df; }
  .header_navigation.scroll {
    top: 0;
    position: fixed; }
    .header_navigation.scroll .navigation_icon_search {
      display: flex; }
    .header_navigation.scroll .top_navigation {
      display: none; }
    .header_navigation.scroll .navigation_another_phone.active {
      top: 80px;
      position: fixed; }
    .header_navigation.scroll .navigation_logo {
      width: 220px; }
      .header_navigation.scroll .navigation_logo img {
        width: 100%; }
    .header_navigation.scroll .navigation_phone_info {
      display: none; }
    .header_navigation.scroll .middle_navigation_shop {
      display: flex;
      align-items: center;
      justify-content: center; }
    .header_navigation.scroll .navigation_search {
      display: none; }
    .header_navigation.scroll .middle_navigation_menu {
      width: 100%;
      overflow-x: auto;
      overflow-y: hidden; }
      .header_navigation.scroll .middle_navigation_menu .top_navigation_link {
        display: flex; }
      .header_navigation.scroll .middle_navigation_menu::-webkit-scrollbar {
        width: 2px;
        height: 2px; }
    .header_navigation.scroll .middle_navigation_scroll {
      min-width: 950px; }

.schedule {
  margin-left: 50px; }
  @media (max-width: 992px) {
    .schedule {
      display: none; } }

.top_navigation {
  display: flex;
  align-items: center;
  height: 35px;
  justify-content: flex-end;
  border-bottom: 1px solid #e0e0e0; }
  .top_navigation_shop {
    display: flex;
    align-items: center; }
    .top_navigation_shop svg path {
      transition: all 0.2s;
      stroke: #273b4a; }
    .top_navigation_shop_text {
      transition: all 0.2s;
      padding-left: 10px; }
    .top_navigation_shop:hover svg path {
      stroke: #73cac2; }
    .top_navigation_shop:hover .top_navigation_shop_text {
      color: #73cac2; }
  .top_navigation_link {
    transition: all 0.2s;
    margin-left: 20px; }
    .top_navigation_link:hover {
      color: #73cac2; }

.middle_navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px; }
  .middle_navigation_shop {
    cursor: pointer; }

.navigation_logo img {
  width: 100%; }

.navigation_main_phone svg {
  margin-right: 5px; }

.navigation_main_phone path,
.navigation_main_phone span {
  transition: all 0.2s; }

.navigation_main_phone_info {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.navigation_main_phone:hover path {
  fill: #73cac2; }

.navigation_main_phone:hover span {
  color: #73cac2; }

.navigation_another_close {
  display: none; }

.navigation_another_phone {
  transition: all 0.2s;
  position: absolute;
  top: 30px;
  z-index: -1;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  width: 200px;
  background: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid #000;
  padding: 10px; }
  .navigation_another_phone a {
    padding-bottom: 5px; }
  .navigation_another_phone.active {
    top: 50px;
    z-index: 9;
    overflow: auto;
    visibility: visible;
    opacity: 1; }

.navigation_phone_arrow {
  position: absolute;
  top: 3px;
  right: -20px;
  cursor: pointer;
  transition: all 0.2s; }
  .navigation_phone_arrow:hover {
    transform: scale(1.2); }

.navigation_phone__block {
  position: relative; }

.navigation_search {
  background: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  z-index: 20;
  position: relative;
  max-width: 550px;
  width: 100%;
  height: 55px; }
  @media (max-width: 1700px) {
    .navigation_search {
      max-width: 450px; } }
  @media (max-width: 1500px) {
    .navigation_search {
      max-width: 400px; } }
  @media (max-width: 1450px) {
    .navigation_search {
      max-width: 365px; } }
  .navigation_search img {
    margin-right: 20px;
    margin-top: 6px; }
  .navigation_search_input {
    width: 100%;
    height: 100%;
    background: transparent;
    outline: none;
    border: none;
    padding: 0px 20px; }
  .navigation_search .navigation_main_phone_info_img {
    position: absolute;
    transition: all 0.2s;
    right: 20px; }
  .navigation_search.open_search {
    display: flex !important;
    position: absolute;
    right: 260px; }

.navigation_icon {
  transition: all 0.2s;
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 15px;
  cursor: pointer; }
  .navigation_icon__block {
    display: flex;
    align-items: center;
    width: 15%;
    justify-content: flex-end; }
  .navigation_icon_search {
    display: none; }
  .navigation_icon_wallet_count {
    position: absolute;
    top: -2px;
    left: 20px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #73cac2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
  .navigation_icon_wallet_price {
    margin-left: 12px; }
  .navigation_icon:hover {
    transform: scale(1.2); }

.mobile_bottom_icon__block {
  display: none !important; }

.bottom_icon__block_mob {
  display: none;
  background: #f2f2f2;
  border-bottom: 1px solid #fff; }
  @media (max-width: 992px) {
    .bottom_icon__block_mob {
      display: block; } }
  .bottom_icon__block_mob .bottom_icon {
    padding: 10px 21px;
    margin-left: 0; }

.mobile_navigation_link {
  display: none; }

.bottom_navigation {
  padding-bottom: 10px;
  padding-top: 10px;
  align-items: flex-start;
  justify-content: space-between; }
  .bottom_navigation_link__block {
    width: 100%;
    display: flex;
    justify-content: space-between; }
    @media (max-width: 1300px) {
      .bottom_navigation_link__block {
        flex-wrap: wrap;
        justify-content: normal; } }

.bottom_icon {
  margin-left: 30px;
  padding-bottom: 2px;
  transition: all 0.2s;
  border-bottom: 1px solid transparent;
  display: flex;
  flex-wrap: nowrap; }
  .bottom_icon img {
    margin-left: 4px; }
  .bottom_icon:nth-child(1):hover {
    border-bottom: 1px solid #000; }
  .bottom_icon:nth-child(2):hover {
    border-bottom: 1px solid #73cac2; }
  .bottom_icon:nth-child(3):hover {
    border-bottom: 1px solid #eb5757; }
  .bottom_icon__block {
    display: flex;
    align-items: center; }

.mobile_navigation_content_btn {
  display: none; }

.bottom_navigation_content__block.test {
  opacity: 1;
  z-index: 99;
  visibility: visible;
  top: 45px; }

.bottom_navigation {
  position: relative; }
  .bottom_navigation_name {
    cursor: pointer;
    transition: all 0.2s; }
    .bottom_navigation_name:hover {
      color: #73cac2; }

.bottom_navigation_link.active .bottom_navigation_content__block {
  z-index: 9;
  height: 100%;
  opacity: 1;
  visibility: visible; }

.bottom_navigation_link.active .bottom_navigation_content_section {
  height: auto; }

.bottom_navigation_content__block {
  transition: all 0.2s;
  transition-delay: 0.1s;
  height: auto;
  position: absolute;
  width: 90%;
  padding: 20px 15px;
  left: 5%;
  background: #fff;
  border-top: 2px solid #73cac2;
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  opacity: 0;
  z-index: -1;
  visibility: hidden;
  top: 70px;
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.166);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.166);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.166); }
  .bottom_navigation_content__block.active {
    z-index: 9;
    height: 100%;
    opacity: 1;
    visibility: visible; }

.bottom_navigation_content_section {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap; }
  @media (max-width: 992px) {
    .bottom_navigation_content_section {
      height: 0; } }

.bottom_navigation_content_btn {
  position: relative;
  display: block;
  cursor: pointer;
  width: 16px;
  height: 16px; }
  .bottom_navigation_content_btn::after {
    content: "";
    transition: all 0.2s;
    position: absolute;
    width: 14px;
    height: 1px;
    left: 50%;
    top: 50%;
    background: #73cac2;
    transform: translate(-50%, -50%); }
  .bottom_navigation_content_btn::before {
    content: "";
    transition: all 0.2s;
    position: absolute;
    width: 1px;
    height: 14px;
    left: 50%;
    top: 50%;
    background: #73cac2;
    transform: translate(-50%, -50%); }
  .bottom_navigation_content_btn:hover:after, .bottom_navigation_content_btn:hover:before {
    background: #437973;
    transform: translate(-50%, -50%) rotate(180deg) scale(1.2); }

.bottom_navigation_content_item {
  display: flex;
  flex-direction: column;
  transition: all 0.2s;
  width: 20%;
  height: fit-content;
  margin-bottom: 10px; }
  .bottom_navigation_content_item.active .bottom_navigation_content__underblock {
    height: auto;
    opacity: 1; }
  .bottom_navigation_content_item.active .bottom_navigation_content_name {
    color: #73cac2; }
  .bottom_navigation_content_item.active .bottom_navigation_content_btn::before {
    content: "";
    width: 0px;
    transform: translate(50%, 50%) rotate(180deg) scale(1.2); }

.bottom_navigation_content_link {
  transition: all 0.2s;
  margin-bottom: 5px; }
  .bottom_navigation_content_link:hover {
    color: #73cac2; }

.bottom_navigation_content_section {
  width: 100%;
  padding: 0px 10px; }

.bottom_navigation_content_name {
  transition: all 0.2s; }
  .bottom_navigation_content_name:hover {
    color: #73cac2; }
  .bottom_navigation_content_name__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    justify-content: space-between;
    padding-right: 20px;
    width: fit-content; }

.bottom_navigation_content_btn {
  margin-left: 20px; }

.bottom_navigation_content__underblock {
  transition: all 0.1s;
  height: 0;
  opacity: 0;
  display: flex;
  flex-direction: column;
  width: 80%; }

.social-container {
  display: flex;
  align-items: center;
  margin: 10px 0;
  margin-top: 10px;
  justify-content: space-between; }

.btn_sociate {
  transition: all 0.2s;
  display: flex;
  align-items: center; }
  .btn_sociate svg {
    transition: all 0.2s;
    width: 20px;
    margin-right: 20px; }
  .btn_sociate a {
    font-size: 14px; }
  .btn_sociate_2 svg {
    width: 15px; }

.mini_modal {
  transition: all 0.2s;
  position: fixed;
  top: 50%;
  left: -100%;
  width: 300px;
  height: auto;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  z-index: -1;
  opacity: 0;
  -webkit-box-shadow: 10px 10px 5px 0px #8a878a;
  -moz-box-shadow: 10px 10px 5px 0px #8a878a;
  box-shadow: 10px 10px 5px 0px #8a878a; }
  .mini_modal.active {
    z-index: 9999;
    opacity: 1;
    left: 50%; }
  .mini_modal_close {
    position: absolute;
    width: 20px;
    height: 20px;
    right: 5px;
    top: 5px;
    cursor: pointer; }

.location_triger {
  cursor: pointer; }

.location_link {
  transition: all 0.2s;
  width: 100%;
  padding-bottom: 5px;
  border-bottom: 1px solid #000; }
  .location_link:hover {
    border-bottom: 1px solid #73cac2;
    color: #73cac2; }

.mobile_icon {
  display: none; }

.header-likes_counter {
  transition: all 0.2s; }

.overlay {
  transition: all 0.2s;
  background-color: rgba(0, 0, 0, 0.708);
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: -1;
  bottom: -100%;
  right: 0;
  opacity: 0;
  overflow: hidden; }
  .overlay.active {
    z-index: 9;
    bottom: 0;
    opacity: 1; }

@media (min-width: 992px) {
  .bottom_navigation {
    position: relative; }
    .bottom_navigation_link {
      margin-right: 10px; }
      .bottom_navigation_link:hover .bottom_navigation_content__block {
        opacity: 1;
        z-index: 99;
        visibility: visible;
        top: 45px; } }

@media (max-width: 1650px) {
  .bottom_navigation_name {
    font-size: 14px; } }

@media (max-width: 1480px) {
  .bottom_navigation_content_name {
    font-size: 14px; } }

@media (max-width: 1400px) {
  .navigation_search {
    display: none; }
  .navigation_icon_search {
    display: flex !important; }
  .middle_navigation_scroll {
    justify-content: flex-start; }
  .navigation_logo {
    display: block;
    margin-right: 50px; }
  .header_navigation.scroll .navigation_logo {
    margin-right: 20px; }
  .header_navigation.scroll .navigation_main_phone_info {
    margin: 0px 20px; } }

@media (max-width: 1350px) {
  .bottom_icon__block {
    display: none; }
  .bottom_navigation {
    justify-content: center; } }

@media (max-width: 992px) {
  .bottom_navigation_content__underblock {
    margin-left: 20px; }
  .lang__block {
    margin-left: 0px;
    display: flex;
    padding: 10px 21px;
    background: #f2f2f2;
    border-bottom: 1px solid #fff; }
    .lang__block_mobile {
      display: flex; }
  .lang_link {
    margin-left: 0px;
    margin-right: 15px;
    font-size: 17px; }
  .mobile_icon {
    transition: all 0.6s;
    display: flex;
    position: absolute;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    box-shadow: 0px 20px 15px rgba(43, 0, 0, 0.1);
    border-radius: 50%;
    z-index: 9999;
    opacity: 1; }
  .mobile_phone {
    transition-delay: 0.2s;
    top: calc(90vh - 50px);
    right: 5%;
    background: #fff; }
  .mobile_location {
    top: calc(90vh - 105px);
    right: 5%;
    background: #73cac2; }
  .header_navigation {
    animation: none !important;
    position: fixed; }
    .header_navigation.scroll .mobile_icon {
      opacity: 0;
      right: -100px; }
    .header_navigation.header_navigation_delivery .middle_navigation {
      padding-top: 10px; }
  .bottom_navigation {
    position: fixed;
    transition: all 0.2s;
    left: -100%;
    background: rgba(242, 242, 242, 0.55);
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 99999;
    padding: 0px;
    align-items: flex-start;
    width: 100%;
    height: calc(100% - 115px);
    top: 115px; }
    .bottom_navigation_name {
      width: 100%;
      display: flex;
      padding: 12px 21px; }
    .bottom_navigation_link {
      padding: 0px;
      margin-right: 0;
      background: #000000e3;
      border-bottom: 1px solid #fff;
      color: #fff; }
      .bottom_navigation_link a {
        color: #fff; }
      .bottom_navigation_link__block {
        display: flex;
        flex-direction: column;
        width: 100%; }
      .bottom_navigation_link.active .bottom_navigation_content__block {
        visibility: visible;
        opacity: 1;
        z-index: 99;
        padding: 10px 5px;
        padding-top: 0;
        height: auto; }
    .bottom_navigation.delivery {
      top: 145px;
      height: calc(100% - 145px); }
  .mobile_navigation_link {
    display: flex;
    padding: 18px 21px;
    margin-right: 0;
    background: #f2f2f2;
    border-bottom: 1px solid #fff; }
    .mobile_navigation_link:hover .bottom_navigation_content__block {
      top: 0; }
  .header_navigation {
    height: 95px; }
    .header_navigation.active .bottom_navigation {
      left: 0; }
  .bottom_navigation_content__block {
    position: relative;
    transition-delay: unset;
    top: 0 !important;
    left: 0;
    background: transparent;
    flex-direction: column;
    width: 100%;
    border-top: unset;
    z-index: -1;
    height: 1px;
    opacity: 0;
    padding: 0;
    visibility: hidden; }
  .bottom_navigation_content__underblock {
    height: auto;
    opacity: 1; }
  .bottom_navigation_content_section {
    padding: 0;
    padding-left: 5px; }
  .bottom_navigation_content_link {
    padding-left: 5px; }
  .bottom_navigation_content_btn {
    display: none; }
  .bottom_navigation_content_section {
    width: 100%; }
  .navigation_search,
  .header_navigation.scroll .navigation_search {
    display: flex; }
  .header_navigation.scroll .middle_navigation_scroll {
    min-width: unset; }
  .top_navigation_link,
  .middle_navigation_shop,
  .navigation_phone__block,
  .navigation_icon_wallet_price,
  .navigation_icon_search,
  .top_navigation {
    display: none !important; }
  .header_navigation .navigation_logo {
    width: 180px !important; }
  .bottom_icon__block {
    display: none; }
  .mobile_bottom_icon__block {
    display: flex !important;
    position: absolute;
    top: 145px;
    left: 0;
    width: 100%;
    height: 55px;
    background: #fff;
    justify-content: flex-end;
    padding: 10px 5%; }
  .hidden_menu__block {
    display: flex;
    position: absolute;
    height: 55px;
    width: 100%;
    position: absolute;
    top: 94px;
    background: #f2f2f2; }
  .menu_line {
    transition: all 0.2s;
    position: absolute;
    width: 24px;
    height: 4px;
    background: #ffffff;
    border-radius: 5px; }
    .menu_line_1 {
      top: 18px;
      margin-left: -6px; }
    .menu_line_2 {
      margin-left: 6px;
      top: 26px; }
    .menu_line_3 {
      top: 34px;
      margin-left: -6px; }
  .menu_btn {
    background: #73cac2;
    width: 55px;
    height: 55px;
    position: relative;
    overflow: hidden;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .menu_btn.active .menu_line_1 {
      transform: rotate(45deg);
      top: 25px; }
    .menu_btn.active .menu_line_2 {
      margin-left: -100px; }
    .menu_btn.active .menu_line_3 {
      transform: rotate(-45deg);
      top: 25px; }
  .navigation_search {
    position: absolute;
    top: 94px;
    left: 55px;
    background: red;
    height: 55px;
    z-index: 999;
    background: transparent;
    border-radius: unset;
    max-width: unset;
    width: calc(100vw - 55px); }
    .navigation_search img {
      margin-top: 0; }
  .mobile_navigation_content_btn {
    position: absolute;
    right: 0;
    top: 7px;
    display: block;
    cursor: pointer;
    width: 36px;
    height: 36px; }
    .mobile_navigation_content_btn:before {
      content: "";
      transition: all 0.2s;
      position: absolute;
      width: 1px;
      height: 14px;
      left: 50%;
      top: 50%;
      background: #73cac2;
      transform: translate(-50%, -50%); }
    .mobile_navigation_content_btn:after {
      content: "";
      transition: all 0.2s;
      position: absolute;
      width: 14px;
      height: 1px;
      left: 50%;
      top: 50%;
      background: #73cac2;
      transform: translate(-50%, -50%); }
  .bottom_navigation_name {
    align-items: center;
    justify-content: space-between; }
  .bottom_navigation_content_section {
    flex-direction: column; }
  .bottom_navigation_content_item {
    width: 100%;
    padding-left: 15px; }
  .bottom_navigation_content_name__wrapper {
    padding-left: 5px; }
  .bottom_navigation_link {
    position: relative; }
    .bottom_navigation_link.active .mobile_navigation_content_btn:before {
      opacity: 0; } }

@media (max-width: 460px) {
  .mobile_bottom_icon__block {
    display: none !important; }
  .social-container {
    flex-direction: column;
    margin: 0; }
  .btn_sociate {
    width: 100%;
    margin-top: 10px; }
    .btn_sociate_1 {
      margin-right: 0; }
      .btn_sociate_1 svg {
        margin-right: 14px; }
  .btn_sociate_2 {
    margin-right: 0;
    margin-bottom: 10px; }
  .row {
    flex-direction: column;
    align-items: center; } }

@media (max-width: 440px) {
  .delivery_hint__block {
    padding: 0px 20px;
    justify-content: space-between; }
  .delivery_hint_text {
    font-size: 10px; }
  .delivery_hint_close {
    position: relative;
    right: 0; } }

@media (max-width: 420px) {
  .header_navigation.header_navigation_delivery .hidden_menu__block {
    top: 94px; }
  .hidden_menu__block {
    top: 60px; }
  .middle_navigation {
    padding-bottom: 10px;
    padding-top: 10px; }
  .header_navigation .navigation_logo {
    width: 140px !important;
    margin-right: 20px;
    display: flex; } }

body.prevent_scrolling {
  overflow: hidden; }

.bottom__icon-brands {
  display: none; }
