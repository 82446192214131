.footer {
  position: relative; }
  .footer_nav__block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px; }
  .footer_nav_link__block {
    display: flex;
    align-items: center;
    padding: 30px 0; }
  .footer_nav_link_info {
    margin-right: 70px; }
  .footer_nav_link_icon {
    transition: all .2s;
    background: #73CAC2;
    border-radius: 8px;
    width: 46px;
    height: 46px;
    margin-right: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer; }
    .footer_nav_link_icon:hover {
      box-shadow: 0px 10px 10px rgba(115, 202, 194, 0.5);
      transform: scale(1.1); }
    .footer_nav_link_icon__block {
      display: flex;
      align-items: center; }
  .footer_nav_page {
    transition: all .2s;
    margin-left: 30px; }
    .footer_nav_page__block {
      display: flex;
      align-items: center;
      padding-left: 20px; }
    .footer_nav_page:hover {
      color: #73CAC2; }
  .footer_contact__block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 13px;
    padding-bottom: 30px;
    border-top: 1px solid #E0E0E0; }
  .footer_contact_payment {
    margin-left: 10px; }
    .footer_contact_payment__block {
      display: flex;
      align-items: center; }

@media (max-width: 1380px) {
  .footer_nav__block {
    flex-direction: column; } }

@media (max-width: 992px) {
  .footer_nav_scroll__block {
    overflow-x: auto;
    width: 100%;
    padding-bottom: 6px; }
    .footer_nav_scroll__block::-webkit-scrollbar {
      width: 2px;
      height: 2px; }
  .footer_nav_page {
    margin: 0px 15px; }
    .footer_nav_page__block {
      padding-left: 0;
      width: 763px; }
  .footer_contact {
    margin: 8px 0px; }
    .footer_contact__block {
      flex-direction: column;
      padding-top: 0px;
      padding-bottom: 10px;
      border-top: unset; } }

@media (max-width: 420px) {
  .footer_nav_link__block {
    flex-direction: column;
    justify-content: center;
    align-items: center; }
  .footer_nav_link_info {
    margin-right: 0;
    margin-bottom: 15px; }
  .footer_nav_link_icon {
    margin: 0px 8px; } }
