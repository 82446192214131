.arsenal_title {
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  font-weight: 400; }
  .arsenal_title_0 {
    font-size: 12px; }
  .arsenal_title_1 {
    font-size: 14px; }
  .arsenal_title_2 {
    font-size: 16px; }
  .arsenal_title_3 {
    font-size: 18px; }
  .arsenal_title_4 {
    font-size: 20px; }
  .arsenal_title_5 {
    font-size: 24px; }
  .arsenal_title_7 {
    font-size: 40px; }
  .arsenal_title_8 {
    font-size: 50px; }
  .arsenal_title_9 {
    font-size: 70px; }

.sub_title {
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  font-weight: 300; }
  .sub_title_0 {
    font-size: 12px; }
  .sub_title_1 {
    font-size: 14px; }
  .sub_title_2 {
    font-size: 16px; }

.regular_title {
  font-weight: 400;
  font-stretch: normal;
  font-style: normal; }
  .regular_title_0 {
    font-size: 12px; }
  .regular_title_1 {
    font-size: 14px; }
  .regular_title_2 {
    font-size: 16px; }
  .regular_title_3 {
    font-size: 18px; }
  .regular_title_4 {
    font-size: 20px; }

.middle_title {
  font-weight: 500;
  font-stretch: normal;
  font-style: normal; }
  .middle_title_0 {
    font-size: 12px; }
  .middle_title_1 {
    font-size: 14px; }
  .middle_title_2 {
    font-size: 16px; }
  .middle_title_3 {
    font-size: 18px; }

.standart_title {
  font-weight: 600;
  font-stretch: normal;
  font-style: normal; }
  .standart_title_0 {
    font-size: 12px; }
  .standart_title_1 {
    font-size: 14px; }
  .standart_title_2 {
    font-size: 16px; }
  .standart_title_3 {
    font-size: 18px; }

.bold_title {
  font-weight: 700;
  font-stretch: normal;
  font-style: normal; }
  .bold_title_0 {
    font-size: 12px; }
  .bold_title_1 {
    font-size: 14px; }
  .bold_title_2 {
    font-size: 16px; }
  .bold_title_3 {
    font-size: 20px; }
  .bold_title_4 {
    font-size: 30px; }
  .bold_title_6 {
    font-size: 50px; }

.bigger_title {
  font-weight: 800;
  font-stretch: normal;
  font-style: normal; }
  .bigger_title_0 {
    font-size: 12px; }
  .bigger_title_1 {
    font-size: 14px; }
  .bigger_title_2 {
    font-size: 16px; }
  .bigger_title_4 {
    font-size: 20px; }
  .bigger_title_8 {
    font-size: 120px; }

.color_black {
  color: #000000; }

.color_white {
  color: #fff; }

.color_red {
  color: #EB5757; }

.color_green {
  color: #73CAC2; }

.color_gray {
  color: #586B7A; }

.color_blue {
  color: #103989; }

.color_pink {
  color: #feafd0; }

@media (max-width: 670px) {
  .arsenal_title_5 {
    font-size: 18px; } }

@media (max-width: 540px) {
  .arsenal_title {
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    font-weight: 400; }
    .arsenal_title_0 {
      font-size: 12px; }
    .arsenal_title_1 {
      font-size: 14px; }
    .arsenal_title_2 {
      font-size: 16px; }
    .arsenal_title_3 {
      font-size: 18px; }
    .arsenal_title_4 {
      font-size: 20px; }
    .arsenal_title_5 {
      font-size: 24px; }
    .arsenal_title_7 {
      font-size: 40px; }
    .arsenal_title_8 {
      font-size: 50px; }
    .arsenal_title_9 {
      font-size: 70px; }
  .sub_title {
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    font-weight: 300; }
    .sub_title_0 {
      font-size: 11px; }
    .sub_title_1 {
      font-size: 12px; }
    .sub_title_2 {
      font-size: 14px; }
  .regular_title {
    font-weight: 400;
    font-stretch: normal;
    font-style: normal; }
    .regular_title_0 {
      font-size: 11px; }
    .regular_title_1 {
      font-size: 12px; }
    .regular_title_2 {
      font-size: 14px; }
    .regular_title_3 {
      font-size: 16px; }
    .regular_title_4 {
      font-size: 18px; }
  .middle_title {
    font-weight: 500;
    font-stretch: normal;
    font-style: normal; }
    .middle_title_0 {
      font-size: 11px; }
    .middle_title_1 {
      font-size: 12px; }
    .middle_title_2 {
      font-size: 14px; }
    .middle_title_3 {
      font-size: 16px; }
  .standart_title {
    font-weight: 600;
    font-stretch: normal;
    font-style: normal; }
    .standart_title_0 {
      font-size: 11px; }
    .standart_title_1 {
      font-size: 12px; }
    .standart_title_2 {
      font-size: 14px; }
    .standart_title_3 {
      font-size: 16px; }
  .bold_title {
    font-weight: 700;
    font-stretch: normal;
    font-style: normal; }
    .bold_title_0 {
      font-size: 11px; }
    .bold_title_1 {
      font-size: 12px; }
    .bold_title_2 {
      font-size: 14px; }
    .bold_title_4 {
      font-size: 26px; }
    .bold_title_6 {
      font-size: 44px; }
  .bigger_title {
    font-weight: 800;
    font-stretch: normal;
    font-style: normal; }
    .bigger_title_0 {
      font-size: 11px; }
    .bigger_title_1 {
      font-size: 12px; }
    .bigger_title_2 {
      font-size: 14px; }
    .bigger_title_4 {
      font-size: 18px; }
    .bigger_title_8 {
      font-size: 40px; } }

@media (max-width: 420px) {
  .arsenal_title_7 {
    font-size: 28px; } }
