.btn_standart {
  transition: all .2s;
  min-width: 170px;
  display: block;
  cursor: pointer;
  width: -moz-fit-content;
  width: fit-content;
  letter-spacing: normal;
  border-radius: 4px;
  text-align: center;
  display: flex;
  justify-content: center;
  border: none;
  outline: none;
  padding: 14.5px 10px; }
  .btn_standart_green {
    background: #73CAC2;
    box-shadow: 0px 10px 10px rgba(115, 202, 194, 0.5);
    border-radius: 8px;
    border: solid 1px transparent !important; }
    .btn_standart_green:hover {
      border: solid 1px #5ca79f !important;
      background: #5ca79f; }
  .btn_standart_white {
    background: #fff;
    box-shadow: 0px 10px 10px rgba(115, 202, 194, 0.5);
    border-radius: 8px;
    border: solid 1px transparent !important; }
    .btn_standart_white:hover {
      border: solid 1px #bdd2d0 !important;
      background: #bdd2d0;
      color: #fff; }

.btn_not-found {
  padding: 5px 10px;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  color: black; }
  @media (max-width: 1000px) {
    .btn_not-found {
      width: min-content;
      font-size: 12px; } }
